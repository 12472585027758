<script lang="ts" setup>
import { isString } from 'lodash-es'
import IconBase from '~/modules/svg/IconBase.vue';
import { StringUtils } from '~/utils/stringUtils';
import { IconBaseViewBox } from './types/iconBaseViewBox';

interface Props {
  commonSize?: string;
  widthIcon?: string;
  heightIcon?: string;
  viewBox?: IconBaseViewBox | string;
}

const props = withDefaults(defineProps<Props>(), {
  commonSize: '20px',
  widthIcon: '',
  heightIcon: '',
  viewBox: ''
});

const width = computed(() => props.widthIcon || props.commonSize);
const height = computed(() => props.heightIcon || props.commonSize);

const preparedViewBox = computed(() => {
  if (isString(props.viewBox)) {
    return StringUtils.isStringNotEmpty(props.viewBox) ? props.viewBox : `0 0 ${width.value} ${height.value}`;
  }

  return `0 0 ${props.viewBox.width} ${props.viewBox.height}`;
});
</script>

<template>
  <div class="icon-base-container">
    <IconBase
      class="icon-base-container__icon"
      :width="width"
      :height="height"
      :view-box="preparedViewBox"
    >
      <slot />
    </IconBase>
  </div>
</template>

<style lang="scss">
.icon-base-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-base-container__icon {
  color: inherit;
}
</style>
